@import '../shared/constants.scss';
@import '../shared/mixins.scss';

.home {
    padding-top: 6px;
}

.toolbar {
    background: linear-gradient( 0.25turn,rgba(19,193,221,1), rgba(0,37,84, 0.9) )
}

.image {
    height: 70px;
    width: auto;
}

.card {
    width: 100%;
    height: calc(100vh);
    overflow-y: auto !important;
    &--blur {
        background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        backdrop-filter: blur(4px);
        width: 100%;
        height: calc(100vh);
        overflow-y: auto !important;
    }

    &--content {
        color: rgb(0, 0, 0);
        font-size: 1.3em;
        font-display: block;
        text-align: justify;
        margin: 20px;
        padding-bottom: 4em;
    }

    &--h {
        color: rgb(0, 0, 0);
        font-size: 1.5em;
        font-weight: 400;
        text-align: left;
    }
}

.action {
    margin: 30px;
    justify-content: center;
}