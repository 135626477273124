@import '../shared/constants.scss';
@import '../shared/mixins.scss';

.signature {
    &__layout {
        width: 100%;
        height: calc(100vh - #{$headerHeight});
        overflow-y: auto !important;
    }

    &__grid {
        align-items: flex-start;
        align-content: flex-start;
        background: linear-gradient( rgba(255,255,255, 0.508), rgba( 255,255,255, 0.1) ), url('../assets/images/signature-panel-background.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: calc(100vh - #{$headerHeight});
        /* @media screen and (max-width: 1280px) {
            height: calc(100vh - #{$headerHeight});
        }*/
        overflow-x: hidden;
        -ms-overflow-style: none;

        &--blur {
            position: relative;
            overflow-y: auto;
            background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
            backdrop-filter: blur(4px);
            width: 100%;
            height: 100%;
        }

        &-canvas {
            align-content: flex-start;
            align-items: flex-start;
        }
    }

    &__actions {
        position: absolute;
        bottom: 20px;
        width: calc(100% - 80px);

         @media screen and (max-width: 1280px) {
           bottom:auto;
           margin-top:20px;
        }
    }
}
