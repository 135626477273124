@import 'shared/colors.scss';
@import 'shared/mixins.scss';
/* Provide sufficient contrast against white background */

.webviewer {
    height: 100vh;
}

.sigCanvas {
    border: 2px dashed #00000080;
    /*background: $translucentBackground;*/
    border-radius: 2px;
}

.wrap {
    word-wrap: break-word;
}

.sig {
    overflow-y: hidden;
    padding-bottom: 120px !important;
}

.sig-pad {
    border: 0;
    background: $translucentBackground;
    border-radius: 2px;
    padding: 12px;

    label {
        font-size: 12px;
    }
}

.MuiFilledInput-root {
    background: $translucentBackground !important;
}

.mt {
    &-20 {
        margin-top: 20px !important;
    }

    &-40 {
        margin-top: 40px !important;
    }
}

.p-40 {
    padding: 40px;
}

.pb {
    &-40 {
        padding-bottom: 40px;
    }
}

.fr {
    float: right;
}

.scale-075 {
    transform: scale(0.75);
}
